import styled from "@emotion/styled";
import { ButtonProps } from "goi_common";

const RenewalButton = ({ children, onClick, className, disabled }: ButtonProps) => {
  return (
    <Button className={className} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default RenewalButton;

const Button = styled("button")`
  padding: 15px 20px;
  border-radius: 5px;
  color: white;
  background-color: var(--warmGray850);
`;
