// common
type ValueOf<T> = T[keyof T];

const SCHEMA_ORG = "https://schema.org" as const;
type SchemaOrg = typeof SCHEMA_ORG;

type DefaultRichSearchType = {
  "@context": SchemaOrg;
  ["@type"]: ValueOf<RichSearchTypes>;
};

type HasNameRichSearchType = {
  /** 제품명, 조직명 등 대표 name */
  name: string;
} & DefaultRichSearchType;

/**
 RICH_SEARCH_TYPES 와 RICH_SEARCH_CONTENT_TYPES가 무엇인지에 대한 예시
 *
 {
  "@context": SCHEMA_ORG,
   "@type": "FAQPage", <-- RICH_SEARCH_TYPES
   "mainEntity": [
    {
      "@type": "Question", <-- RICH_SEARCH_CONTENT_TYPES
      "name": "NAVER 1784의 주소는 무엇입니까?",
      "acceptedAnswer": {
        "@type": "Answer", <-- RICH_SEARCH_CONTENT_TYPES
        "text": "경기 성남시 분당구 정자일로 95입니다."
      }
    },
   ]
 }
 *  
 */

const RICH_SEARCH_TYPES = {
  AGGREGATE_RATING: "AggregateRating",
  FAQ_PAGE: "FAQPage",
  ADDRESS: "LocalBusiness",
  REVIEW: "Product",
} as const;

const RICH_SEARCH_CONTENT_TYPES = {
  faqPage: {
    question: "Question",
    answer: "Answer",
  },
  aggregateRating: {
    product: "Product",
    place: "Place",
  },
  address: {
    postalAddress: "PostalAddress",
  },
  review: "Review",
} as const;

type RichSearchTypes = typeof RICH_SEARCH_TYPES;
type RichSearchContentTypes = typeof RICH_SEARCH_CONTENT_TYPES;

export { RICH_SEARCH_TYPES, RICH_SEARCH_CONTENT_TYPES };
export type { RichSearchTypes, RichSearchContentTypes };

// FAQ rich search
type FAQRichSearch = {
  "@type": RichSearchTypes["FAQ_PAGE"];
  mainEntity: FAQQuestion[];
} & DefaultRichSearchType;

type FAQQuestion = {
  "@type": ValueOf<RichSearchContentTypes["faqPage"]["question"]>;
  name: string;
  acceptedAnswer: FAQAnswer;
};

type FAQAnswer = {
  "@type": ValueOf<RichSearchContentTypes["faqPage"]["answer"]>;
  text: string;
};

type FAQItem = {
  question: string;
  answer: string;
};

const getFAQRichSearch = (FAQTexts: FAQItem[]): FAQRichSearch => {
  const defaultFAQRichSearch: FAQRichSearch = {
    "@context": SCHEMA_ORG,
    "@type": RICH_SEARCH_TYPES.FAQ_PAGE,
    mainEntity: [],
  };

  const defaultFAQAnswer: FAQAnswer = {
    "@type": RICH_SEARCH_CONTENT_TYPES.faqPage.answer,
    text: "",
  };

  const defaultFAQQuestion: FAQQuestion = {
    "@type": RICH_SEARCH_CONTENT_TYPES.faqPage.question,
    name: "",
    acceptedAnswer: defaultFAQAnswer,
  };

  const faqContent = FAQTexts.map(({ question, answer }) => ({
    ...defaultFAQQuestion,
    name: question,
    acceptedAnswer: {
      ...defaultFAQAnswer,
      text: answer,
    },
  }));

  const faqRichSearch: FAQRichSearch = {
    ...defaultFAQRichSearch,
    mainEntity: faqContent,
  };

  return faqRichSearch;
};

// Product aggregate rating rich search
type AggregateRatingRichSearch = {
  "@context": SchemaOrg;
  "@type": ValueOf<RichSearchTypes["AGGREGATE_RATING"]>;
} & AggregateRatingVariable;

type AggregateRatingVariable = {
  /** 제품(Product) 혹은 장소(Place)의 이름, 타이틀입니다. */
  name: string;
  aggregateRating: AggregateRatingOriginContent;
};

type AggregateRatingOriginContent = {
  ["@type"]: RichSearchTypes["AGGREGATE_RATING"];
  /** 평점을 나타내는 점수 값 입니다. 0~5 사이 값이 기본 값입니다. */
  ratingValue: string;
  /** 평가 체계에서 가장 높은 값입니다. 값이 없는 경우 5를 기본 값으로 사용합니다. ratingValue 값보다 큰 값이어야 합니다. */
  bestRating?: string;
  /** 평점에 참여된 평점의 총개수입니다. */
  ratingCount?: string;
  /** 평점과 함께 또는 평점이 없이 입력된 리뷰의 개수입니다 */
  reviewCount?: string;
};

type AggregateRatingOmitContent = Omit<AggregateRatingOriginContent, "@type">;

const getAggregateRating = (
  type: ValueOf<RichSearchContentTypes["aggregateRating"]>,
  data: AggregateRatingVariable,
) => {
  const defaultProductAggregate: AggregateRatingRichSearch = {
    "@context": SCHEMA_ORG,
    "@type": type,
    ...data,
  };

  const AggregateRichSearch = {
    ...defaultProductAggregate,
  };

  return AggregateRichSearch;
};

type AddressRichSearch = {
  address: AddressOriginContentType;
} & HasNameRichSearchType;

type AddressOriginContentType = {
  ["@type"]: ValueOf<RichSearchContentTypes["address"]>;
  /** 숫자 혹은 하이픈(-)으로 구성해 우편번호를 입력해 주세요. */
  postalCode?: string;
  /** 주소 중 가장 큰 지역명을 입력해 주세요. */
  addressRegion?: string;
  /** Region 하위 지역을 입력해 주세요.  */
  addressLocality?: string;
  /** 도로명 포함 상세 주소를 입력해 주세요. */
  streetAddress: string;
};

type AddressOmitContentType = Omit<AddressOriginContentType, "@type">;

function getAddressRichSearch(
  name: string,
  content: AddressOriginContentType | AddressOmitContentType,
): AddressRichSearch {
  const defaultHasNameRichSearch: HasNameRichSearchType = {
    ["@context"]: SCHEMA_ORG,
    ["@type"]: RICH_SEARCH_TYPES.ADDRESS,
    name: "",
  };

  const resultAddressContent: AddressOriginContentType = {
    ["@type"]: RICH_SEARCH_CONTENT_TYPES["address"]["postalAddress"],
    ...content,
  };

  return {
    ...defaultHasNameRichSearch,
    name,
    address: resultAddressContent,
  };
}

/** review rich search */

type ReviewRichSearch = {
  review: [];
} & HasNameRichSearchType;

type ReviewOriginContentType = {
  "@type": ValueOf<RichSearchContentTypes["review"]>;
  reviewBody: string;
  reviewRating?: {
    "@type": "Rating";
    ratingValue: string;
    bestRating: string;
    worstRating: string;
  };
  author: {
    "@type": "Person";
    name: string;
  };
};

type ReviewOmitContentType = Omit<ReviewOriginContentType, "@type">;

const getReviewRichSearch = (name: string, newReviewData: ReviewOmitContentType[]) => {
  const defaultHasNameRichSearch: HasNameRichSearchType = {
    ["@context"]: SCHEMA_ORG,
    ["@type"]: RICH_SEARCH_TYPES.REVIEW,
    name,
  };

  const reviews = newReviewData.map((review) => ({ "@type": "Review", ...review }));

  return {
    ...defaultHasNameRichSearch,
    review: reviews,
  };
};

export type { FAQRichSearch, FAQQuestion, FAQAnswer, FAQItem };
export type {
  AggregateRatingRichSearch,
  AggregateRatingOriginContent,
  AggregateRatingOmitContent,
  AggregateRatingVariable,
};
export type { AddressRichSearch, AddressOriginContentType, AddressOmitContentType };
export type { ReviewRichSearch, ReviewOriginContentType, ReviewOmitContentType };

export { getFAQRichSearch, getAggregateRating, getAddressRichSearch, getReviewRichSearch };
