import { VStack } from "goi_common";

interface Props {
  title: string;
  desc: string;
}

export const GoiServiceItem = ({ title, desc }: Props) => {
  return (
    <VStack gap="8px">
      <div className="subtitle_14_b">{title}</div>
      <div className="body_14_m">{desc}</div>
    </VStack>
  );
};
