import { Link, VStack, customEvent, getCdnImageSrc } from "goi_common";
import { GoiServiceItem } from "./GoiServiceItem";
import RenewalButton from "../Button/RenewalButton";

export const GoiService = () => {
  const handleClick = () => {
    customEvent({
      newGtm: {
        path: "home",
        trigger_and_target: "goi_service-uv_service",
      },
    });
  };

  return (
    <div
      id="home-service"
      css={{
        scrollMarginTop: "100px",
        padding: "35px 24px 32px",
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        position: "relative",
        zIndex: 1,
        "&:before": {
          content: "''",
          backgroundImage: `url('${getCdnImageSrc(
            "platform/home/goi-service-flower-background.png",
          )}')`,
          backgroundSize: "cover",
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          opacity: 0.5,
          zIndex: -1,
        },
      }}
    >
      <div>
        <div className="subtitle_21_sb">고이 서비스</div>
        <div className="bidy_16_r">추가 금액 유도 없는 합리적인 서비스입니다.</div>
      </div>
      <VStack
        gap="40px"
        borderRadius="10px"
        background=" rgba(235, 224, 224, 0.50)"
        padding="17px 16px 32px"
      >
        <VStack gap="32px">
          <p className="body_16_m gray800">
            고이는 가족분들의 장례가 상술로 얼룩진 기억이 아닌 합리적이고 따뜻한 기억이 될 수 있도록{" "}
            <span
              css={{
                fontWeight: 700,
              }}
            >
              3가지 약속
            </span>
            을 지키고 있습니다.
          </p>
          <GoiServiceItem
            title="업계 최초 품목별 가격 정찰제"
            desc="무엇이 얼마인지, 그 비용을 투명하게 공개하는 것은 당연한 일입니다. 불필요한 혜택이나
              상품 없이 꼭 필요한 것만 모아 비용을 낮추고, 업계 최초로 모든 품목에 가격 정찰제를
              도입하여 실제로 사용한 품목만 비용을 받습니다."
          />
          <GoiServiceItem
            title="사용하지 않은 품목 100% 현금 공제"
            desc="사용하지 않은 품목이 있을 때, 그 비용을 지불하지 않는 것은 당연한 일입니다. ‘권리
        포기로 간주', ‘다른 품목으로 대체 제공'과 같은 관행을 거부하고 패키지 내 사용하지 않은
        품목에 대해 명시된 금액만큼 100% 현금으로 공제해 드립니다."
          />
          <GoiServiceItem
            title="서비스 불만족시 100% 환불 정책"
            desc="고객님께서 수고비를 주시지 않는 것은 당연한 일입니다. 감사한 마음은 따뜻한 말 한마디와
            정성스러운 후기로 대신 받겠습니다. 좋은 서비스는 계약에 이미 포함되어 있는 것으로서
            장례 용품 강매, 노잣돈 및 수고비 강요 등의 부적절한 행위를 엄격히 금지하고 있습니다."
          />
        </VStack>
        <VStack alignItems="center">
          <Link href="/service/">
            <RenewalButton onClick={handleClick} className="button_14_sb">
              고이 표준 서비스 바로 가기
            </RenewalButton>
          </Link>
        </VStack>
      </VStack>
    </div>
  );
};
