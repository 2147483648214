import { ColorIcon, HStack, VStack, colors, customEvent } from "goi_common";
import RenewalRoundedButton from "./Button/RenewalRoundedButton";
import Link from "next/link";

const GoiMessage = () => {
  const handleLeftClick = () => {
    customEvent({
      newGtm: {
        path: "home",
        trigger_and_target: "goi_msg-uv_fnrl_pre",
      },
    });
  };

  const handleRightClick = () => {
    customEvent({
      newGtm: {
        path: "home",
        trigger_and_target: "goi_msg-uv_service",
      },
    });
  };

  return (
    <VStack
      padding="38px 16px 37px 24px"
      gap="31px"
      css={{
        backgroundColor: colors.gray900,
      }}
    >
      <VStack gap="15px">
        <p className="subtitle_21_b white">고이는,</p>
        <p className="body_16_m white">
          장례 서비스도 투명하고
          <br />
          합리적으로 소비할 수 있는 세상,
          <br />
          장례지도사와 유가족 간에 따뜻한 경험이 남는 세상,
          <br />
          누군가의 죽음이 특별하게 기억될 수 있는 세상을 만들고 있습니다.
        </p>
      </VStack>
      <HStack gap="10px">
        <Link href="/funeral-prepare/company-introduction/">
          <RenewalRoundedButton onClick={handleLeftClick}>
            <HStack gap="8px" className="button_14_sb gray900">
              <span>고이는 어떤 곳인가요?</span>
              <ColorIcon name="icon-arrow-right-circle-mono" color={colors.gray900} width="14px" />
            </HStack>
          </RenewalRoundedButton>
        </Link>
        <Link href="/service/">
          <RenewalRoundedButton onClick={handleRightClick}>
            <HStack gap="8px" className="button_14_sb orange700">
              <span>표준 서비스 확인</span>
              <ColorIcon
                name="icon-arrow-right-circle-mono"
                color={colors.orange700}
                width="14px"
              />
            </HStack>
          </RenewalRoundedButton>
        </Link>
      </HStack>
      <p className="caption_12_m gray600">
        ※고이는 올해 초부터 소비자중심경영(CCM) 등록을 준비하고 있으며, 더욱더 고객 중심으로
        운영하기 위해 노력하고 있습니다.
      </p>
    </VStack>
  );
};

export default GoiMessage;
