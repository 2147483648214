import styled from "@emotion/styled";
import { ButtonProps } from "goi_common";

const RenewalRoundedButton = ({ children, className }: ButtonProps) => {
  return <Button className={className}>{children}</Button>;
};

export default RenewalRoundedButton;

const Button = styled("button")`
  padding: 10px 12px;
  border-radius: 16px;
  background-color: var(--white);
`;
