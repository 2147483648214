import { Box, Stack } from "@chakra-ui/react";
import {
  colors,
  customEvent,
  EventProps,
  getCdnImageSrc,
  HStack,
  Image,
  Link,
  Spacing,
  VStack,
} from "goi_common";

import HomeRequestBox from "./HomeRequestBox";
import HomeSearchBox from "./HomeSearchBox";
import HomeBannerSection from "./HomeBannerSection";

export function HeroSection() {
  return (
    <>
      <VStack
        width="100%"
        alignItems="center"
        background={`url(${getCdnImageSrc("platform/home/home_hero_back.png")})`}
        backgroundSize="cover"
        backgroundPosition="center"
        padding="130px 0 40px"
      >
        <VStack maxWidth="480px" width="100%" justifyContent="center">
          <VStack css={{ color: "white" }} margin={"0px 18px 32px"}>
            <div className={"subtitle_21_sb"}>사용한 만큼만 내고 추가 구매 유도 없는</div>
            <div className={"head_24_b"}>합리적인 맞춤형 장례, 고이</div>
          </VStack>

          <VStack maxWidth="480px" width="100%" padding="0 16px">
            <HomeRequestBox path="home" position="top" hasSocialProof />
          </VStack>
        </VStack>
      </VStack>

      <VStack width="100%">
        <HomeBannerSection />

        <VStack
          padding="48px 24px 20px"
          gap="10px"
          background={colors.gray50}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%" className="subtitle_21_sb" textAlign="left">
            고이 주요 서비스
          </Box>
          <Spacing gap={20} />
          <HStack width="100%" gap={"16px"}>
            <ListButton
              href="/match/request/"
              imgSrc="request"
              text="장례 예상 견적"
              isRecommended
              event={{
                newGtm: {
                  path: "home",
                  trigger_and_target: "shrtcut_btn_mtch_rqst-uv_mtch_rqst",
                },
              }}
              isLarge
            />
            <ListButton
              href="/funeral-prepare/company-introduction/"
              imgSrc="intro"
              text="고이 소개"
              event={{
                newGtm: {
                  path: "home",
                  trigger_and_target: "shrtcut_btn_intro-uv_cmpny_intro",
                },
              }}
              isLarge
            />
          </HStack>
          <HStack width="100%" gap={"16px"}>
            <ListButton
              href="/service/"
              imgSrc="service_intro"
              text="고이 상품 안내"
              event={{
                newGtm: {
                  path: "home",
                  trigger_and_target: "shrtcut_btn_service-uv_service",
                },
              }}
            />
            <ListButton
              href="#home-service"
              imgSrc="price"
              text="고이 가격 정책"
              event={{
                newGtm: {
                  path: "home",
                  trigger_and_target: "shrtcut_btn_price-move_home_service",
                },
              }}
            />
          </HStack>
          <HStack width="100%" gap={"16px"}>
            <ListButton
              href="/guidebook/summary/before/funeral-process/"
              imgSrc="process"
              text="장례 절차"
              event={{
                newGtm: {
                  path: "home",
                  trigger_and_target: "shrtcut_btn_prcss-uv_gidbok_prcss",
                },
              }}
            />
            <ListButton
              href="/funeral-prepare/1/"
              imgSrc="prepare"
              text="장례 준비법"
              event={{
                newGtm: {
                  path: "home",
                  trigger_and_target: "shrtcut_btn_prpre-uv_fnrl_prpre_1",
                },
              }}
            />
          </HStack>

          <HomeSearchBox />
        </VStack>
      </VStack>
      <Spacing gap={20} />
    </>
  );
}

function ListButton({
  href,
  imgSrc,
  text,
  event,
  isRecommended,
  isLarge,
}: {
  href: string;
  imgSrc: string;
  text: string;
  event: EventProps;
  isRecommended?: boolean;
  isLarge?: boolean;
}) {
  return (
    <Link
      href={href}
      onClick={() => {
        customEvent(event);
      }}
      css={{ width: "100%" }}
    >
      {isRecommended && (
        <Box position="absolute" margin="-28px 0px 0 -6px">
          <svg
            width="52"
            height="38"
            viewBox="0 0 52 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 5C0 2.23858 2.23858 0 5 0H47C49.7614 0 52 2.23858 52 5V24C52 26.7614 49.7614 29 47 29H0V5Z"
              fill="#E2452B"
            />
            <path d="M7 38V29H0L7 38Z" fill="#523528" />
          </svg>
          <Box className="caption_12_sb white" position="absolute" margin="-32px 0 0 16px">
            추천
          </Box>
        </Box>
      )}
      <Box
        height="100%"
        alignItems="center"
        gap={4}
        padding={isLarge ? "16px 12px 6px 12px" : "13px 12px"}
        borderRadius="8px"
        boxShadow="0px 0px 12px rgba(0, 0, 0, 0.12)"
        background="white"
        justifyContent="center"
        wordBreak="keep-all"
      >
        <Stack
          flexDirection={isLarge ? "column" : "row"}
          width="100%"
          height="100%"
          alignItems={isLarge ? "end" : "center"}
          justifyContent="space-between"
        >
          <Box className={"subtitle_16_b gray800"} width="100%">
            {text}
          </Box>

          <Image
            src={getCdnImageSrc(`platform/home/renewal/${imgSrc}.png`)}
            alt={text}
            width={"50px"}
            style={{ margin: "0 -8px 0 0" }}
          />
        </Stack>
      </Box>
    </Link>
  );
}
