import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ColorIcon, colors, HStack } from "goi_common";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

interface BaseProps {
  type?: "primary" | "outline" | "standard" | "solo";
  disabled?: boolean;
  clearable?: boolean;
  borderColor?: string;
  iconColor?: string;
  placeholder?: string;
  suffix?: ReactNode;
  onClick?: () => void;
  onInputClick?: () => void;

  onEnter?: () => void;
  isFocused?: boolean;
}

interface Controlled {
  value: string;
  onChange: (value: string) => void;
}
interface Uncontrolled {
  value?: string;
  onChange?: (value: string) => void;
}

type Props = (Controlled | Uncontrolled) & BaseProps;

const Search = ({
  type = "primary",
  disabled = false,
  clearable = false,
  iconColor = colors.gray900,
  placeholder = "장례식장을 검색하세요",
  value,
  suffix,
  onChange,
  onClick,
  onInputClick,
  onEnter,

  isFocused,
}: Props) => {
  const hasOnClickEventInput = Boolean(onInputClick);

  const inputRef = useRef<HTMLInputElement>(null);
  const [_value, _setvalue] = useState<string>("");
  const rootProps = useMemo(() => {
    if (type === "solo") {
      return {
        background: colors.white,
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: "12px",
        padding: "11px 16px",
      };
    } else if (type === "primary") {
      return {
        background: colors.white,
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.04)",
        borderRadius: "12px",
        padding: "11px 16px",
      };
    } else if (type === "standard") {
      return {
        background: colors.white,
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        padding: "11px 16px",
      };
    }
  }, []);
  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus();
    }
  }, [inputRef, isFocused]);
  return (
    <HStack
      as={onClick ? "button" : "div"}
      alignItems="center"
      onClick={onClick}
      width="100%"
      {...rootProps}
    >
      <ColorIcon name="search" color={iconColor} width={28} height={28} />
      <div style={{ width: "8px" }}></div>
      {hasOnClickEventInput ? (
        <Box
          className={`"body3_m gray500`}
          onClick={() => {
            onInputClick?.();
          }}
        >
          {placeholder}
        </Box>
      ) : (
        <Input
          ref={inputRef}
          className={`"body3_m gray900`}
          placeholder={placeholder}
          value={value ?? _value}
          onChange={(e) => (onChange ? onChange(e.target.value) : _setvalue(e.target.value))}
          disabled={disabled || Boolean(onClick)}
          onClick={() => {
            onInputClick?.();
          }}
          onKeyDown={(e) => {
            e.key === "Enter" && onEnter?.();
          }}
          type={type}
          hasClickAction={Boolean(onClick)}
        />
      )}
      {clearable && (
        <div
          onClick={() => (onChange ? onChange?.("") : _setvalue(""))}
          style={{ margin: "0 11.5px", cursor: "pointer" }}
        >
          <ColorIcon name="clear" color={colors.gray300} size={15} />
        </div>
      )}
      {suffix}
    </HStack>
  );
};
const Input = styled.input<{ hasClickAction: boolean; type: BaseProps["type"] }>`
  outline: none;
  width: 100%;
  background: transparent;

  ::placeholder {
    color: var(--gray500);
  }

  ${(props) =>
    props.hasClickAction &&
    css`
      cursor: pointer;
    `}
  ${(props) =>
    props.type === "standard" &&
    css`
      ::placeholder {
        color: var(--gray400);
      }
    `}

  ${(props) =>
    props.type === "solo" &&
    css`
      ::placeholder {
        color: var(--gray500);
      }
    `}
`;

export default Search;
