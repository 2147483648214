import { Box } from "@chakra-ui/react";

import { NextLink, Spacing, VStack } from "goi_common";

import React from "react";
import RenewalButton from "../Button/RenewalButton";
import HomeSelectedReviews from "./HomeSelectedReviews";

export function SelectedAndReviewAndGuideSection() {
  return (
    <VStack width="100%">
      <Box padding="30px 24px 0">
        <div className={"subtitle_21_sb"}>
          한번 뿐인 장례,
          <br />
          아무한테나 맡길 수 없습니다.
        </div>
        <div className={"subtitle_14_sb gray700"}>
          고객님들이 직접 작성한 장례지도사 후기를 확인해보세요.
        </div>
        <HomeSelectedReviews />
        <VStack alignItems="center">
          <NextLink href="/review/">
            <RenewalButton className="button_14_sb">후기 전체 보기</RenewalButton>
          </NextLink>
        </VStack>
      </Box>
      <Spacing gap={50} />
    </VStack>
  );
}
