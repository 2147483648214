import {
  ColorIcon,
  HStack,
  VStack,
  NextLink,
  customEvent,
  ClickEventType,
  getCdnImageSrc,
} from "goi_common";

import dayjs from "dayjs";
import { Box } from "@chakra-ui/react";

interface Review {
  id: number;
  headline: string;
  score: number;
  created_at: string;
  user: {
    nickname: string;
  };
  isCurrent?: boolean;
  event: ClickEventType;
  good: string;
  bgColor: string;
  color: string;
  bgImage: string;
  contentColor: string;
  editColor: string;
  serviceName: string;
}

export function HomeRenewalReviewCard({
  id,
  headline,
  created_at,
  good,
  user,
  score,
  isCurrent,
  event,
  bgColor,
  color,
  bgImage,
  contentColor,
  editColor,
  serviceName,
}: Review) {
  return (
    <VStack
      gap={14}
      width={"100%"}
      height="300px"
      position="relative"
      margin="16px 0"
      justifyContent="space-between"
      css={{
        background: `url(${getCdnImageSrc(`platform/home/${bgImage}.png`)})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NextLink
        href={`/review/${id}/`}
        onClick={() => {
          customEvent({ newGtm: event });
        }}
      >
        <VStack padding="54px 20px 0" gap="24px" flex={1}>
          <Box className="subtitle_16_sb" style={{ color: contentColor }}>
            {good.slice(0, 130)}...
          </Box>
          <div className="body3_m" style={{ color: editColor }}>
            {user.nickname}님 | {dayjs(created_at).format("YYYY년 M월 D일")}
          </div>
        </VStack>
      </NextLink>

      <NextLink
        href={`/service/${serviceName}/`}
        onClick={() => {
          customEvent({ newGtm: event });
        }}
      >
        <HStack alignItems="center" justifyContent="center" background={bgColor} padding="21px 0">
          <Box className="subtitle_14_sb" color={color}>
            위 고객님이 이용하신 서비스 보기
          </Box>
          <ColorIcon name="arrow_right_small" color={color} size={16} />
        </HStack>
      </NextLink>
    </VStack>
  );
}
