import { Box } from "@chakra-ui/react";
import {
  ColorIcon,
  colors,
  customEvent,
  EventTriggerAndTargetType,
  FUNERAL_SIDO_LIST,
  FUNERAL_SIGUNGU,
  GrayNonBorderDropdown,
  GraySpinner,
  HStack,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  Spacing,
  Spinner,
  VStack,
} from "goi_common";

import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isLoggedInState } from "@/atoms/accountState";
import { useDialog } from "@/hooks/useDialog";
import { getSangjoQuotationRequestExists, QuotationResponse } from "@/apis/sangjoQuotation";
import { OverlayModal } from "goi_common";
import {
  장례준비긴급도_LIST,
  조문객예상인원_LIST,
} from "@/containers/MatchContainer/constants/request";
import { MatchRequestProps } from "@/containers/MatchContainer/type/requestType";
import CustomRequestModalContent from "@/components/Match/CustomRequestModalContent";
import { matchRequestLoadingState } from "@/atoms/match/matchRequestLoadingState";
import { matchRequestResDataState } from "@/atoms/match/matchRequestResDataState";
import { temporaryMatchRequestState } from "@/containers/MatchContainer/atoms";
import { useOverlay } from "@toss/use-overlay";
import QuotationExistContent from "./QuotationExistContent";
import { useSangjoQuotationRequestExistModal } from "@/hooks/useSangjoQuotationRequestExistModal";
import useSangjoQuotationRequests from "@/hooks/useSangjoQuotationRequests";
import { AxiosResponse } from "axios";

const SIDO_LIST = FUNERAL_SIDO_LIST.map((sido) => ({
  key: sido[1] as string,
  value: sido[0],
}));

const 긴급도_LIST = 장례준비긴급도_LIST.map((res) => ({ key: res, value: res }));

const 조문객_LIST = 조문객예상인원_LIST.map((res) => ({ key: res, value: res }));

export default function HomeRequestBox({
  path = "home",
  position,
  hasSocialProof = false,
  isResponsive = true,
}: {
  path: "home";
  position: "top" | "bt";
  hasSocialProof?: boolean;
  isResponsive?: boolean;
}) {
  const eventPosition = position ? position + "-" : "";

  const [selectSigungu, setSelectSigungu] = useState(true);
  const [disableModalError, setDisableModalError] = useState(true);
  const [SelectedOption, setSelectedOption] = useReducer(
    (prev: MatchRequestProps, next: any) => {
      return { ...prev, ...next };
    },
    {
      장례준비긴급도: "급하지 않지만 미리 알아보려고 해요.",
      sido: 0,
      sigungu: 0,
      조문객수: "100명 내외",
    },
  );

  const { setQuotationRequestCookie, mutateSangjoQuotation } = useSangjoQuotationRequests();

  useEffect(() => {
    if (SelectedOption.sido) {
      setSelectSigungu(false);
      setDisableModalError(false);
    }

    if (SelectedOption.sigungu) {
      setSelectSigungu(true);
    }
  }, [SelectedOption]);

  const isLoggedIn = useRecoilValue(isLoggedInState);

  const { onOpen, onClose } = useDialog();

  const { onOpenRequestExistModal } = useSangjoQuotationRequestExistModal();

  const isDisabled = useMemo(() => {
    return (
      !SelectedOption.장례준비긴급도 ||
      SelectedOption.sido === "0" ||
      !SelectedOption.sido ||
      !SelectedOption.sigungu ||
      !SelectedOption.조문객수
    );
  }, [SelectedOption]);
  const handleSubmit = useCallback(async () => {
    if (loading) return;

    customEvent({
      newGtm: {
        path: path,
        trigger_and_target:
          `${eventPosition}submit_conversion` as EventTriggerAndTargetType["home"],
      },
    });

    if (isDisabled) {
      onOpen({
        type: 1,
        closeOnOverlayClick: false,
        header: "응답하지 않은 항목이 있어요",
        children: <>다시 확인해주세요.</>,
        footerProps: {
          text: "확인",
          onClick: onClose,
        },
      });
      setDisableModalError(true);
      setSelectSigungu(true);
      return;
    }

    try {
      setLoading(true);

      const body = {
        status: SelectedOption.장례준비긴급도,
        funeral_sido: SelectedOption.sido,
        funeral_sigungu: SelectedOption.sigungu,
        needs_binso: SelectedOption.조문객수 === "0명(무빈소)" ? false : true,
        condolers:
          SelectedOption.조문객수 === "없음"
            ? ""
            : SelectedOption.조문객수 === "0명(무빈소)"
            ? "0명"
            : (SelectedOption.조문객수 as string),
      };

      const onSuccess = ({ data }: AxiosResponse<QuotationResponse>) => {
        setQuotationRequestCookie(data);

        customEvent({
          newGtm: {
            action: "match_request_complete",
          },
          triggerHackle: true,
        });

        overlay.open(({ isOpen, close: onClose }) => {
          return (
            <OverlayModal
              onClose={onClose}
              isOpen={isOpen}
              modalContentStyle={{
                minWidth: "320px",
                overflow: "scroll",
              }}
              overlayModalContent={<CustomRequestModalContent onClose={onClose} />}
            />
          );
        });
        setTemporaryMatchRequest(data.id);
        setResponseData(data);

        setLoading(false);
      };

      const onError = () => {
        setLoading(false);
      };

      mutateSangjoQuotation({
        body,
        onSuccess,
        onError,
      });
    } catch (e: any) {
      if (e.response.status === 409) {
        onOpenRequestExistModal();
      }
      setLoading(false);
    }
  }, [SelectedOption]);

  const [loading, setLoading] = useRecoilState(matchRequestLoadingState);
  const setResponseData = useSetRecoilState<any>(matchRequestResDataState);

  const overlay = useOverlay();

  const [, setTemporaryMatchRequest] = useRecoilState(temporaryMatchRequestState);

  const [isQuotationExist, setIsQuotationExist] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      getSangjoQuotationRequestExists()
        .then((quotation) => {
          setIsQuotationExist(quotation.request_exists);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isLoggedIn]);

  const date = new Date();
  const day = date.getDate();

  const quotationCount = useMemo(() => {
    if (day % 3 === 0) {
      return 320;
    } else if (day % 3 === 1 || day === 1) {
      return 270;
    } else if (day % 3 === 2 || day === 2) {
      return 290;
    }
  }, [day]);

  return (
    <VStack width="100%" padding={"0px"}>
      <VStack
        padding={"24px 20px 24px"}
        alignItems="center"
        background="white"
        boxShadow="0px 0px 12px rgba(0, 0, 0, 0.12)"
        width={"100%"}
        css={{ minHeight: "300px" }}
        borderRadius={10}
      >
        {isLoading ? (
          <VStack
            justifyContent="center"
            alignItems="center"
            width="100%"
            css={{ minHeight: "300px" }}
          >
            <GraySpinner />
          </VStack>
        ) : (
          <VStack padding={"0px"} width="100%" alignItems="center">
            {isQuotationExist ? (
              <QuotationExistContent
                isResponsive={isResponsive}
                path={path}
                position={eventPosition}
              />
            ) : (
              <VStack maxWidth={"520px"} width="100%">
                <div className={"subtitle_16_sb gray600"}>
                  100만원, 500만원 아끼면서 <br />
                  장례를 치르려면?
                  <br />
                  <span className="subtitle_21_b gray900">{`장례 비용 알아보기`}</span>
                </div>
                <div className={"caption_12_b gray500"}>
                  비용이 전부는 아니지만, 합리적으로 지출하면서 <br />
                  추모에만 집중할 수 있습니다.
                </div>
                <Spacing gap={24} />
                <Box
                  display="flex"
                  gap="20px"
                  width={"100%"}
                  flexDir={"column"}
                  alignItems={"initial"}
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    gap="20px"
                    width={"100%"}
                    flexDir={"column"}
                    alignItems={"initial"}
                    justifyContent="center"
                  >
                    <VStack gap={8} width={"100%"}>
                      <HStack alignItems="center" gap={4}>
                        <ColorIcon
                          name="icon-twinkle-circle-mono"
                          color={colors.gray400}
                          size="16px"
                        />
                        <div className="body_14_m gray500">긴급도</div>
                      </HStack>
                      <VStack width="100%">
                        <GrayNonBorderDropdown
                          name="긴급도"
                          values={긴급도_LIST}
                          value={SelectedOption.장례준비긴급도}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedOption({ 장례준비긴급도: e.target.value });
                            customEvent({
                              newGtm: {
                                path: path,
                                trigger_and_target:
                                  `${eventPosition}status-answer` as EventTriggerAndTargetType["home"],
                              },
                            });
                          }}
                        />
                      </VStack>
                    </VStack>
                    <VStack position="relative" gap={8} css={{ minWidth: "202px" }}>
                      <HStack alignItems="center" gap={4}>
                        <ColorIcon
                          name="icon-pin-location-mono"
                          color={colors.gray400}
                          size="16px"
                        />
                        <div className="body_14_m gray500">지역</div>
                      </HStack>

                      <HStack gap="12px" width={"100%"}>
                        <GrayNonBorderDropdown
                          name="--"
                          values={SIDO_LIST}
                          value={SelectedOption.sido}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedOption({ sido: e.target.value, sigungu: 0 });
                            customEvent({
                              newGtm: {
                                path: path,
                                trigger_and_target:
                                  `${eventPosition}sido-answer` as EventTriggerAndTargetType["home"],
                              },
                            });
                          }}
                        />
                        <GrayNonBorderDropdown
                          name="--"
                          values={
                            SelectedOption.sido
                              ? Array.from(FUNERAL_SIGUNGU.get(Number(SelectedOption.sido))!).map(
                                  (sigungu) => ({
                                    key: sigungu[1],
                                    value: sigungu[0],
                                  }),
                                )
                              : []
                          }
                          value={SelectedOption.sigungu}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedOption({ sigungu: e.target.value });
                            customEvent({
                              newGtm: {
                                path: path,
                                trigger_and_target:
                                  `${eventPosition}sigungu-answer` as EventTriggerAndTargetType["home"],
                              },
                            });
                          }}
                        />
                      </HStack>
                      {disableModalError && (
                        <div
                          css={{ position: "absolute", bottom: -18, left: 5, zIndex: 3 }}
                          className="orange650 button_14_sb"
                        >
                          지역을 선택해주세요.
                        </div>
                      )}
                      {selectSigungu || (
                        <div
                          css={{ position: "absolute", bottom: -18, left: 5, zIndex: 3 }}
                          className="orange650 button_14_sb"
                        >
                          다음 지역을 선택해주세요.
                        </div>
                      )}
                    </VStack>
                  </Box>
                  <Spacing gap={4} />
                  <VStack gap={8} width="100%">
                    <HStack alignItems="center" gap={4}>
                      <ColorIcon name="icon-user-mono" color={colors.gray400} size="16px" />
                      <div className="body_14_m gray500">조문객 수</div>
                    </HStack>
                    <GrayNonBorderDropdown
                      name="조문객 수"
                      values={조문객_LIST}
                      value={SelectedOption.조문객수}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedOption({ 조문객수: e.target.value });
                        customEvent({
                          newGtm: {
                            path: path,
                            trigger_and_target:
                              `${eventPosition}condolers-answer` as EventTriggerAndTargetType["home"],
                          },
                        });
                      }}
                    />
                  </VStack>
                  <VStack maxWidth="100%" width="100%" height="46px">
                    <LargeButton
                      className="button_14_sb"
                      buttonType={
                        isDisabled ? LARGEL_BUTTON_TYPE.DISABLED : LARGEL_BUTTON_TYPE.ORANGE
                      }
                      disabled={isDisabled}
                      onClick={handleSubmit}
                    >
                      {loading ? <Spinner /> : <>전체 장례 비용 알아보기</>}
                    </LargeButton>
                  </VStack>
                </Box>
              </VStack>
            )}
            {hasSocialProof && (
              <VStack width="100%" maxWidth={"100%"}>
                <Box
                  display="flex"
                  flexDir={"column"}
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="24px"
                  padding="8px 10px"
                  background={colors.blue50}
                  className="blue700"
                >
                  <Box
                    className={"body_14_m"}
                    textAlign="center"
                    wordBreak="keep-all"
                    position="relative"
                  >
                    <ColorIcon
                      name="send_outline"
                      size={16}
                      color={colors.blue700}
                      css={{
                        margin: "0 5px 3px 0",
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    />
                    <span>
                      매일 약 <b>{quotationCount}</b>명의 고객님이 비용 알아보기를 <br />
                      통해 장례 비용을 평균 246만원 아끼고 있어요
                    </span>
                  </Box>
                </Box>
              </VStack>
            )}
          </VStack>
        )}
      </VStack>
    </VStack>
  );
}
