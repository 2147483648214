import { HStack, VStack, colors, customEvent } from "goi_common";
import RenewalButton from "./Button/RenewalButton";
import Link from "next/link";
import { useQuickCallModal } from "@/hooks/useQuickCallModal";

export const StandBy24Hours = () => {
  const { onQuickCallModal } = useQuickCallModal();

  const handleInquiryClick = () => {
    onQuickCallModal();

    customEvent({
      newGtm: {
        path: "home",
        trigger_and_target: "24hour_standby-opn_call_mdl",
      },
    });
  };

  const handleEstimateClick = () => {
    customEvent({
      newGtm: {
        path: "home",
        trigger_and_target: "24hour_standby-uv_mtch_rqst",
      },
    });
  };
  return (
    <VStack
      padding="40px 0 41px"
      gap="30px"
      alignItems="center"
      css={{
        backgroundColor: colors.white,
      }}
    >
      <p
        className="subtitle_21_sb gray700"
        css={{
          textAlign: "center",
        }}
      >
        새벽에도, 명절에도
        <br />
        24시간 대기하고 있습니다.
      </p>
      <HStack gap="6px">
        <RenewalButton className="button_14_sb" onClick={handleInquiryClick}>
          장례 문의하기
        </RenewalButton>
        <Link href="/match/request/">
          <RenewalButton className="button_14_sb" onClick={handleEstimateClick}>
            상세 견적 받기
          </RenewalButton>
        </Link>
      </HStack>
    </VStack>
  );
};
