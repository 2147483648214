import Search from "@/components/common/Search";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useOverlay } from "@toss/use-overlay";
import { colors, customEvent, HStack, VStack } from "goi_common";

import React, { useState } from "react";
import SearchModal from "./SearchModal";

export default function HomeSearchBox() {
  const [keyword, setKeyword] = useState<string>("");
  const overlay = useOverlay();

  const moveToResult = () => {
    customEvent({ newGtm: { path: "home", trigger_and_target: "question-opn_mdl" } });
    overlay.open(({ isOpen, close: onClose }) => {
      return <SearchModal path="home" isOpen={isOpen} onClose={onClose} searchText={keyword} />;
    });
  };

  return (
    <VStack
      width="100%"
      padding="28px 0 30px"
      id="home_question"
      css={{ scrollMarginTop: "150px" }}
    >
      <HStack>
        <HStack
          borderRadius={50}
          css={{ border: "2px solid #F18334" }}
          padding="10px"
          width="100%"
          background="white"
        >
          <Search
            type="outline"
            iconColor={colors.gray500}
            value={keyword}
            onChange={setKeyword}
            onEnter={moveToResult}
            placeholder="궁금한 점을 물어보세요"
          />
        </HStack>
        <SearchButton className="button_16_sb orange700" onClick={moveToResult}>
          알아보기
        </SearchButton>
      </HStack>
      <HStack className="caption_12_m gray500" gap="8px" padding="8px 0 0">
        <Box>#장례비용</Box>
        <Box>#장례식장비교</Box>
        <Box>#기초생활수급자장례</Box>
      </HStack>
    </VStack>
  );
}

const SearchButton = styled.button`
  padding: 0 20px;
  width: "100px !important";
  flex-shrink: 0;
`;
