import { getSangjoQuotations } from "@/apis/sangjoQuotation";
import numberToLocalString from "@/utils/numberToLocalString";
import { Box } from "@chakra-ui/react";
import {
  ColorIcon,
  colors,
  customEvent,
  EventTriggerAndTargetType,
  HStack,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  NextLink,
  queryKeys,
  Spacing,
  VLine,
  VStack,
} from "goi_common";
import { useQuery } from "react-query";

export default function QuotationExistContent({
  isResponsive,
  path,
  position,
}: {
  path: "home" | "gdb_tip_bfr" | "my";
  position?: string;
  isResponsive: boolean;
}) {
  const { data } = useQuery(queryKeys.sangjoQuotation, () => getSangjoQuotations());

  if (!data) return null;
  return (
    <>
      {data.quotations.length > 0 && (
        <VStack gap={24} width="100%" maxWidth={"100%"}>
          <div className={"subtitle_18_sb gray900"}>이미 받은 견적이 있어요.</div>
          <Box display="flex" flexDir={"column"} alignItems={"initial"} gap={2} width="100%">
            <HStack gap={2}>
              <VStack gap={8} width={"50%"}>
                <HStack alignItems="center" gap={4}>
                  <ColorIcon name="icon-twinkle-circle-mono" color={colors.gray400} size="16px" />
                  <div className="body_14_m gray500">추천받은 상조</div>
                </HStack>
                <HStack width="100%" gap={8}>
                  <div className={"subtitle_16_b"}>{data.quotations[0].sangjo.name}</div>
                </HStack>
              </VStack>

              <VStack gap={8}>
                <HStack alignItems="center" gap={4}>
                  <ColorIcon name="icon-twinkle-circle-mono" color={colors.gray400} size="16px" />
                  <div className="body_14_m gray500">평균 별점</div>
                </HStack>
                <HStack width="100%" gap={8} alignItems="center">
                  <ColorIcon name="rating_star" size={26} color={colors.orange650} />

                  <div className={"subtitle_16_b"}>{data.quotations[0].sangjo.average_score}</div>
                </HStack>
              </VStack>
            </HStack>

            <VLine width="100%" height="2px" color={colors.gray100} margin="16px 0px" />

            <HStack gap={2}>
              <VStack gap={8} width={"50%"}>
                <HStack alignItems="center" gap={4}>
                  <ColorIcon name="icon-twinkle-circle-mono" color={colors.gray400} size="16px" />
                  <div className="body_14_m gray500">추천받은 서비스</div>
                </HStack>
                <HStack width="100%" gap={8}>
                  <div className={"subtitle_16_b"}>
                    {data.quotations[0].package.name} {data.quotations[0].package.price / 10000}
                  </div>
                </HStack>
              </VStack>

              <VStack gap={8}>
                <HStack alignItems="center" gap={4}>
                  <ColorIcon name="icon-twinkle-circle-mono" color={colors.gray400} size="16px" />
                  <div className="body_14_m gray500">가격</div>
                </HStack>
                <HStack width="100%" gap={8} alignItems="center">
                  <div className={"subtitle_16_b"}>
                    {numberToLocalString(data.quotations[0].package.price)}
                    <span className="subtitle_14_sb gray500"> 원</span>
                  </div>
                </HStack>
              </VStack>
            </HStack>
            <Spacing gap={32} />
            <Box display="flex" flexDir={"column"} width={"100%"} gap={11} marginLeft={"0px"}>
              <VStack width={"100%"}>
                <NextLink href={`/match/recommendation/${data.quotations[0].id}`}>
                  <LargeButton
                    className="button_14_sb"
                    buttonType={LARGEL_BUTTON_TYPE.ORANGE}
                    onClick={() => {
                      customEvent({
                        newGtm: {
                          path: path,
                          trigger_and_target:
                            `${position}alredy_qutatin-uv_mch_recomm` as EventTriggerAndTargetType["home"],
                        },
                      });
                    }}
                  >
                    확인하러 가기
                  </LargeButton>
                </NextLink>
              </VStack>
              <VStack width={"100%"}>
                <NextLink href="/funeral-prepare/1/">
                  <LargeButton
                    className="button_14_sb"
                    buttonType={LARGEL_BUTTON_TYPE.PRIMARY}
                    onClick={() => {
                      customEvent({
                        newGtm: {
                          path: path,
                          trigger_and_target:
                            `${position}consult-uv_funeral_prepare_1` as EventTriggerAndTargetType["home"],
                        },
                      });
                    }}
                  >
                    {"전화 상담이 부담스럽다면"}
                  </LargeButton>
                </NextLink>
              </VStack>
            </Box>
          </Box>
        </VStack>
      )}
    </>
  );
}
