const 전체_태그_후기들 = [
  "투명한 가격 정책이 가장 메리트 있어 보였고, 불필요한 물품에 대한 환불 부분이 믿음을 주었습니다. 사용하지 않은 상복이나 물품의 반품 및 환불도 빠르게 처리해 주셨습니다.",
  "친절한 상담과 따뜻한 위로의 말씀 그리고 자세한 설명에 다시 한번 감사 드립니다. 평소에 죽음과 멀다고 생각하며 안일하게 살다가 갑작스러운 상실에 당황스러움만 있었는데, 죽음에 대한 철학과 경험을 나눠주셔서 특히 감사했습니다.",
  "말만 하고 끝나는 것이 아닌, 진심으로 대한다고 느껴지는 행동과 배려들이 장례를 치르는 동안 굉장한 의지와 힘이 되었습니다.",
  "도움을 많이 주시면서 아픔에 공감해주셔서 조금 더 편하게 문의할 수 있었던 것 같아요. 타인의 감정에, 그것도 전화상으로 공감한다는 것이 쉽지 않다는 것을 압니다.",
  "자신의 가족을 모시듯 염습, 하나하나 꼼꼼하게 해주시는데 깜짝 놀랐습니다. 발인 날 아침 일찍부터 일정, 브리핑을 시작으로 발인제, 운구하고 성남화장에 도착, 모든 절차를 꼼꼼하게 체크해주셨습니다.",
];

const 장례지도사_태그_후기들 = [
  "말만 하고 끝나는 것이 아닌 진심으로 대한다고 느껴지는 행동과 배려들이 장례를 치르는 동안 굉장한 의지와 힘이 되었습니다.",
  "복장부터 다른 상조회사보다 고급지고 단정해서 신뢰가 갔고, 모든 장례절차에서 항상 꼼꼼한 설명과 충분한 시간을 주시면서 유가족들이 충분히 애도할 수 있도록 해주셨습니다.",
  "장례식장 컨택과 납골당까지 꼼꼼히 챙겨주셔서 마음의 의지가 되었습니다. 독자였던 저에게 커다란 도움이 되어주셨습니다. 어머님을 편안히 모셔드리고 저도 이제 일상으로 돌아가려합니다.",
  "자신의 가족을 모시듯 염습을 하나하나 꼼꼼하게 해주시는데 깜짝 놀랐습니다. 발인날 아침 일찍부터 일정, 브리핑을 시작으로 발인제, 운구하고 성남화장 도착, 모든 절차를 꼼꼼하게 체크해주셨습니다.",
  "저희 아버지 장례를 맡아주신 장례지도사님이 정말 너무너무 좋았습니다. 어떠한 것들을 선택해야 할 때, 객관적으로 꼼꼼하게 설명해주시고 가족들의 선택에 맡겨주시는 것에 신뢰가 가고 안심이 되었습니다.",
];

const 상담_태그_후기들 = [
  "친절한 상담과 따뜻한 위로의 말씀 그리고 자세한 설명에 다시 한번 감사드립니다. 평소에 죽음과 멀다고 생각하며 안일하게 살다가 갑작스러운 상실에 당황스러움만 있었는데 덕분에 이 추운 겨울 할머니를 잘 보내드릴 수 있었습니다.",
  "너무나 친절하게 상담해주시고, 이것저것 알아봐주시고, 비교도 해주신 것에 감동했어요. 처음 장례식을 치루게 되어 당황스러운 저희 가족은 고이 덕분에 편안하게 장례를 마칠 수 있었습니다.",
  "도움을 많이 주시면서 아픔에 공감해주셔서 조금 더 편하게 문의할 수 있었던 것 같아요. 타인의 감정에, 그것도 전화상으로 공감한다는 것이 쉽지 않다는 것을 알고 있습니다.",
  "따뜻한 느낌의 상담사 분의 자세한 안내에 따라 장례지도사 분을 소개 받고 사실 반신반의하는 맘도 있었으나 처음부터 끝까지 성심성의껏 도와주셔서 무사히 장례를 잘 마칠 수 있었습니다.",
  "우울함과 경황이 없었던 와중에, 절차를 하나부터 끝까지 옆에서 일일이 챙겨주셔서 너무 감사했습니다. 진심으로 아버지를 가시는 길을 평안하게 잘 보내드릴 수 있도록 도와주셔서 너무 감사드립니다.",
];

const 정책_태그_후기들 = [
  "비용부터 진행과정, 안내까지 모든 것이 매끄럽고 합리적이었다고 생각합니다. 이 감사함과 배려는 오래도록 간직하고 기억하겠습니다.",
  "투명한 가격 정책이 가장 메리트가 있어 보였고, 불필요한 물품에 대한 환불 부분이 믿음을 주었습니다. 고이에서도 사용하지 않은 상복이나 물품의 반품 및 환불도 빠르게 처리해 주셨습니다.",
  "비용 부분에서도 저희는 오히려 안내받았던 예상 금액보다 적어서 만족스러웠습니다.",
  "상조 상품 구성에 있어서도 유연함이 있어 비용 절감을 할 수 있었고 실질적인 팁을 주셔서 매우 유용했습니다.",
  "가격도 합리적이고 무엇보다 고인과 유족을 대하시는 자세가 너무나도 세심하여 정말 감동받았습니다. 최서규 본부장님을 비롯하여 어머니 보내는 길 도와주신 분들께 다시 한번 감사의 말씀드리고 싶습니다.",
];

const 상황_태그_후기들 = [
  "말기암이던 동생이 호스피스에 입원하고 있던 동안 '고이'를 발견하게 되었고, 대표님과 직접 통화를 통해 확정을 내렸습니다. 추가금 없이 무빈소로 진행하기로 했습니다. 장례 내내 추가금도 없었습니다. 적극 추천합니다.",
  "추석 명절 연휴에 상을 당해 당황스러웠고, 경황이 없었는데 친절하게 세세히 안내해주셔서 신뢰가 갔습니다. 특히 장례지도사님께서는 연결이 되자마자 추석 다음날 새벽 1시 25분부터 필요한 조처를 빠르게 진행해주셨습니다.",
  "아무것도 준비 안 됐을 때부터 꼼꼼하고 성의 있는 상담을 해주셔서 마음의 위로가 많이 되었습니다. 장례지도사님도 친절하게 친 이모님처럼 옆에서 챙겨주셔서 너무 감사했습니다. 우리 자매에게 큰 힘을 주셨습니다.",
  "장례플랫폼은 처음이라 계속 살펴보게 되었는데요, 투명한 가격 정책이 가장 메리트가 있어 보였고, 불필요한 물품에 대한 환불 부분이 믿음을 주었습니다.",
  "경황이 없는 저에게 흔들림 없는 고목처럼 든든하고 잠시나마 숨 돌릴 그늘을 만들어 주셨습니다. 진심으로 감사드립니다.",
  "지금이라도 상조에 가입해야 하는지 걱정하던 차에 '고이'를 알게 되어 다행이라는 생각이 들었습니다.",
];

const 후기_리치서치_컨텐츠들 = {
  전체: 전체_태그_후기들,
  장례지도사: 장례지도사_태그_후기들,
  상담: 상담_태그_후기들,
  상황: 상황_태그_후기들,
  정책: 정책_태그_후기들,
};

const 후기_리치서치_컨텐츠_개수들 = {
  전체: "437",
  장례지도사: "151",
  상담: "73",
  상황: "51",
  정책: "59",
};

export { 후기_리치서치_컨텐츠들, 후기_리치서치_컨텐츠_개수들 };
