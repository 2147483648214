import FileRequestModal from "@/containers/Parlor/ParlorDetailContainer/components/Modal/FileRequestModal";
import { Box, Flex } from "@chakra-ui/react";

import { useOverlay } from "@toss/use-overlay";
import { ColorIcon, colors, customEvent, EventProps, getCdnImageSrc, HStack } from "goi_common";
import { ReactNode } from "react";

function HomeStepGuideFooter({
  event = {
    newGtm: {
      path: "parlor_detail",
      trigger_and_target: "step_guide_fixed_button",
    },
  },

  maxWidth = "480px",
  bottom = "80px",
  right = "",
  iconMarginLeft,
  transform = "",
}: {
  type?: string;
  text?: ReactNode;
  event?: EventProps;
  src?: string;
  size?: string;
  maxWidth?: string;
  bottom?: string;
  right?: string;
  iconMarginLeft?: string;
  transform?: string;
}) {
  const overlay = useOverlay();
  const typeToStyle = {
    background: colors.gray800,
    color: colors.white,
  };

  return (
    <Flex alignItems="center" width="100%" flexDirection="column">
      <Box
        css={{
          width: "100%",
          maxWidth: maxWidth || "480px",
          margin: 0,
          position: "fixed",
          bottom: bottom || 80,
          right: right,
          zIndex: 100,
          display: "flex",
          justifyContent: "end",
          transform: transform,
        }}
      >
        <HStack
          as="button"
          onClick={() => {
            customEvent(event);

            overlay.open(({ isOpen, close: onClose }) => {
              return <FileRequestModal path="home" isOpen={isOpen} onClose={onClose} />;
            });
          }}
          css={{
            marginRight: "10px",
            background: `url(${getCdnImageSrc("platform/home/home_request_back.png")})`,
            backgroundSize: "450px 120px",
            backgroundPosition: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 12px rgba(113, 120, 125, 0.4)",
            borderRadius: "36px",
            padding: "8px 20px 8px 20px",
            alignItems: "center",
            justifyContent: "center",
          }}
          gap={8}
        >
          <Box
            className="subtitle_14_b"
            textAlign="left"
            color={typeToStyle.color}
            textShadow="0px 5px 2px rgba(0, 5, 133, 0.10);"
          >
            장례비용 예상 견적표,
            <br />
            임종준비 체크리스트 받아보기
          </Box>
          <ColorIcon
            name="arrow_right_small"
            size={20}
            color={typeToStyle.color}
            css={{ marginLeft: iconMarginLeft || "0px" }}
          />
        </HStack>
      </Box>
    </Flex>
  );
}

export default HomeStepGuideFooter;
