import { Box, Button, Input, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { Checkbox, ColorIcon, colors, customEvent, HStack, VStack } from "goi_common";
import { useCallback, useState } from "react";
import emailjs from "@emailjs/browser";

export default function SearchModal({
  isOpen,
  onClose,
  searchText,
  path = "home",
  hint,
  focusText,
  isSimple,
  title,
}: {
  isOpen: boolean;
  isSimple?: boolean;
  title?: string;
  onClose: () => void;
  searchText: string;
  path: "home" | "parlor_detail" | "service";
  hint?: string;
  focusText?: string;
}) {
  const [type] = useState("번호");
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    if (loading) return;
    customEvent({
      newGtm: {
        path,
        trigger_and_target: "send_email_mdl-btn",
      },
    });
    if (!checked) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }
    if (type === "이메일") {
      alert("현재 이메일 발송 시스템 점검중으로 번호입력만 가능합니다.");
    } else {
      if (!value) {
        alert("번호를 입력해주세요.");
        return;
      } else {
        if (/^010-\d{4}-\d{4}$/.test(value)) {
          emailjs
            .send(
              "service_agc6alu",
              "template_o1ul2fg",
              {
                question: hint ? hint : searchText,
                phone: value,
              },
              "ckK66U9O1M16QnFJq",
            )
            .then(() => {
              customEvent({
                newGtm: { path, trigger_and_target: "send_question_email" },
              });
              alert("요청이 완료되었습니다. 30분 이내로 답변드리겠습니다.");
              setValue("");
              onClose();
              setChecked(false);
              setLoading(false);
            })
            .finally(() => setLoading(false));
        } else if (/^010\d{4}\d{4}$/.test(value)) {
          emailjs
            .send(
              "service_agc6alu",
              "template_o1ul2fg",
              {
                question: hint ? hint : searchText,
                phone: `010-${value.substring(3, 7)}-${value.substring(7, 11)}`,
              },
              "ckK66U9O1M16QnFJq",
            )
            .then(() => {
              customEvent({
                newGtm: { path, trigger_and_target: "send_question_email" },
              });
              alert("요청이 완료되었습니다. 30분 이내로 답변드리겠습니다.");
              setValue("");
              onClose();
              setChecked(false);
              setLoading(false);
            })
            .finally(() => setLoading(false));
        } else {
          alert("번호 형식을 확인해주세요. (010-****-****)");
          return;
        }
      }
    }
  }, [checked, value, type]);
  return (
    <Box height="100%" width="100%">
      <Modal
        isOpen={isOpen}
        onClose={() => {
          customEvent({
            newGtm: {
              path,
              trigger_and_target: "send_email_mdl-close",
            },
            triggerHackle: true,
          });
          onClose();
        }}
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        isCentered={true}
        useInert={false}
      >
        <ModalOverlay width="100%" height="100%" />
        <ModalContent minWidth="300px" backgroundColor={colors.white} backgroundSize="cover">
          <ModalBody padding="20px 24px 44px">
            <VStack width="100%" gap={10}>
              <HStack width="100%" justifyContent="end" alignItems="center">
                <HStack width="100%" alignItems="center" gap={8}>
                  <ColorIcon name="list" color={colors.gray900} width={16} height={16} />
                  <div className="subtitle_21_b gray900">{title || "빠르게 알아보기"}</div>
                </HStack>
                <div
                  onClick={() => {
                    customEvent({
                      newGtm: {
                        path,
                        trigger_and_target: "send_email_mdl-close",
                      },
                      triggerHackle: true,
                    });
                    onClose();
                  }}
                >
                  <ColorIcon name="close" width={28} height={28} color={colors.black} />
                </div>
              </HStack>
              <VStack width="100%" gap={10}>
                {!isSimple && (
                  <>
                    <VStack gap={6} width="100%" className="body_16_m gray800">
                      <Box className="caption_12_b">
                        {searchText && searchText + "에 대해 "} 빠르게 알려드려요.
                      </Box>
                      {focusText && <Box className="subtitle_14_b black">{focusText}</Box>}
                    </VStack>
                  </>
                )}
                <div />
                <Input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  border={`1px solid ${colors.gray400}`}
                  width="100%"
                  placeholder={type === "이메일" ? "이메일을 입력하세요" : "010-****-****"}
                />
                <HStack alignItems="center" justifyContent="space-between">
                  <Checkbox
                    index={2}
                    label="개인정보 수집, 이용, 제공 동의"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                  />
                  <a
                    className="caption_11_b"
                    href="https://goifuneral.notion.site/146a79013e6c40a9b4731dd07a8ad99d"
                    target="_blank"
                  >
                    보기
                  </a>
                </HStack>
                <Button
                  width="100%"
                  backgroundColor={colors.orange650}
                  color={colors.white}
                  onClick={handleClick}
                >
                  확인
                </Button>
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
